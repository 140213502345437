import { Link, graphql } from "gatsby";

import Centered from "../components/Centered";
import { Container } from "react-bootstrap";
import LayoutPage from "../components/LayoutPage";
import React from "react";
import RecentBlogPosts from "../components/RecentBlogPosts";
import Seo from "../components/Seo";
import dayjs from "dayjs";
import useDeckDeckGoLoader from "../modules/useDeckDeckGoLoader";

export const pageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        author
        date
        title
        image
        imageAlt
        path
      }
      html
    }
  }
`;

export default function BlogPost({ data }) {
  const { frontmatter, html } = data.markdownRemark;
  const dateFmt = dayjs(frontmatter.date).format("MMMM D, YYYY");

  useDeckDeckGoLoader();

  return (
    <LayoutPage center>
      <Seo title={frontmatter.title} description={frontmatter.summary} />
      <Container className="pt-5 px-2" fluid>
        <Centered>
          <h1 className="mb-2">{frontmatter.title}</h1>
          <p>
            {frontmatter.author} on {dateFmt}
          </p>
          <img
            src={`/content/blog/${frontmatter.image}`}
            alt={frontmatter.imageAlt}
            className="img-fluid rounded"
            style={{ width: "100%", maxHeight: 400, objectFit: "cover" }}
          ></img>
          <div className="my-5">
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </div>
        </Centered>
        <div>
          <Link to="/blog">
            <h3 className="text-center text-dark">Recent Blog Posts</h3>
          </Link>
          <Centered>
            <RecentBlogPosts excludePath={frontmatter.path} />
          </Centered>
        </div>
      </Container>
    </LayoutPage>
  );
}
